
.solutions-tile, .solutions-tile>span {
    display: block;
}

.solutions-tile {
    background-color: #fff;
    /* box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06); */
    border-radius: 1px;
    border-right: 2px #0000003b dotted;
    height: 100%;
    box-sizing: border-box;
    color: #000;
    transition-property: box-shadow,transform;
    transition-duration: .15s;
}

.solutions-tile, .solutions-tile>span {
    display: block;
}


.solutions-tile__icon {
    font-size: 0;
    line-height: 94px;
    text-align: center;
}
.solutions-tile__icon img{
    /* width: 94px; */
    /* height: 94px; */
    
}

.solutions-tile__title {
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-top:40px;
}
.solutions-tile__desc {
    position: relative;
    overflow: hidden;
    font-weight: 500;
}

a {
    text-decoration: none;
}
@media only screen and (min-width: 768px){
    .grid.-three .grid__row {
        width: 33.33333%;
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .solutions-tile {
        padding: 20px 30px 45px;
    }
    .solutions-tile__title {
        font-size: 22px;
        /* margin-bottom: 10px; */
    }
    .solutions-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}