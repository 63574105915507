footer {
    height: 210px;
    width: 100%;
    background-color: #fff;
    font-weight: 500;
}

footer ul {
    padding: 80px 60px 0;
    text-align: center;
    max-width: 1120px;
    margin: 0 auto;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

footer ul li:first-child {
    /* color: #999; */
}

footer ul li {
    display: inline-block;
    vertical-align: top;
    width: 24%;
    text-align: left;
    color: #000;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 26px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

footer ul li a {
    text-decoration: none;
}

.footer-newsletter-input{
    border: 2px solid #00000087;
    border-radius: 20px;
    font-size: 16px;
    color: black;
    line-height: 1.2;
    padding: 0 20px 0 23px;
    height: 57px;
    width:100%
}

.button-newsletter{
    border-radius: 20px;
    height: 57px;
    width: 50%;
    margin: 0;
    position: relative;
    top: 100%;
    /* left: 25%; */
    margin-top: 12px;
    font-weight: 600;
}

.newsletter-approval{
    font-size: 13px;
    margin-top:10px;
}

.footer-logo{
    width: 140px;
}