.contact-container{
    padding-top: 30px;
    width: 80%;
    margin:auto;
}

@media only screen and (min-width: 768px){
    .contact-container {
        padding-bottom: 30px;
        justify-content: space-between;
    }
    .contact__headline {
        text-align: center;
        padding-bottom: 30px;
    }
    .contact-input-container{
        width: 60%;
        margin: auto;
    }
}

@media only screen and (max-width: 767px){
    .contact-container {
        padding-bottom: 20px;
        justify-content: space-between;
    }
    .contact__headline {
        text-align: center;
        padding-bottom: 20px;
    }
    .contact-input-container{
        margin: auto;
    }
}


.contact-input-element{
    position:relative;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    
}

.input-contact{
    display: block;
    width: 100%;
    background: transparent;
    font-size: 16px;
    line-height: 1.2;
    padding: 0 20px 0 23px;
}

.input-contact-text{
    height: 52px;
}

.input-contact-rich-text{
    height:104px;
}
.input-contact-button{
    height:52px;
    font-weight: 600;
}

button {
    outline: none;
    border: none;
}

input {
    outline: none;
    border: none;
}

