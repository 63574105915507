/* @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption&display=swap');
font-family: 'PT Sans Caption', sans-serif;

@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption&display=swap');
font-family: 'PT Sans Caption', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Martel+Sans&display=swap');
font-family: 'Martel Sans', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');
font-family: 'Lexend Deca', sans-serif; */


@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');

body {
  line-height: 1.8em;
}
body {
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif; 
  color: #000;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  font-weight: 500;
  color: #000;
}

body {
  font-size: 17px;
  line-height: 27px;
}

button {
  outline: none !important;
}

button:focus {
  outline: none !important;
}