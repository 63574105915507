@import url(https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption&display=swap');
font-family: 'PT Sans Caption', sans-serif;

@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption&display=swap');
font-family: 'PT Sans Caption', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Martel+Sans&display=swap');
font-family: 'Martel Sans', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');
font-family: 'Lexend Deca', sans-serif; */

body {
  line-height: 1.8em;
}
body {
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif; 
  color: #000;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%;
}

body {
  font-weight: 500;
  color: #000;
}

body {
  font-size: 17px;
  line-height: 27px;
}

button {
  outline: none !important;
}

button:focus {
  outline: none !important;
}
.header {
    position: fixed;
    /* position: absolute; */
    display: block;

    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    /* transition: background-color .5s ease; */
}
    .header.-upscrolled:not(.-active) {
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
        background-color: #fdfdfd;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }

    .header.-upscrolled:not(.-active) .complex-link, .header.-upscrolled:not(.-active) .header__logo, .header.-upscrolled:not(.-active) .header__menu-link {
        color: #000!important;
    }

    .header.-upscrolled:not(.-active) .header__burger {
        border-color: rgba(0,0,0,.5);
    }
.container {
    position: relative;
    margin: auto;
    max-width: 1268px;
    box-sizing: border-box;
}
.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.header__menu {
    font-weight: 600;
}

.complex-link {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.complex-link.-skin-white {
    color: #fff;
}

a {
    text-decoration: none;
}

.header__menu-link.-highlighted {
    padding: 5px 14px;
}
.header__menu-link {
    -webkit-text-fill-color: currentcolor;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: .3s;
            transition-duration: .3s;
    border: none;
    background: none;
}

.header__overlay {
    position: fixed;
    z-index: 1;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition-property: opacity,visibility;
    transition-property: opacity,visibility;
    -webkit-transition-duration: .3s;
            transition-duration: .3s;
}
.header__burger {
    display: block;
    position: relative;
    z-index: 3;
    border-style: solid;
    border-width: 1px;
    background: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0;
    line-height: 0;
    color: hsla(0,0%,100%,.5);
    -webkit-transition: all .3s;
    transition: all .3s;
}

@media only screen and (min-width: 768px){
    .container {
        width: 90%;
    }
    .header__container {
        -webkit-transition: height .3s;
        transition: height .3s;
        will-change: height;
        height: 98px;
    }
    .header__menu {
        font-size: 16px;
    }
    .header__menu-item {
        display: inline-block;
        vertical-align: top;
        margin-left: 28px;
    }
    /* Contact */
    .header__menu-link.-skin-white.-highlighted {
        background-color: hsla(0,0%,100%,.1);
    }
    .header__menu-link.-skin-white {
        color: #fff;
    }
    .header__burger {
        display: none;
    }
    .header-logo{
        width:120px;
    }

}

@media only screen and (max-width: 767px){
    .container {
        width: 100%;
        padding: 0 22px;
    }
    .header__container {
        margin-top: 5px;
        height: 57px;
    }
    .header__menu {
        font-size: 17px;
        z-index: 1;
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-transition-property: opacity,visibility,-webkit-transform;
        transition-property: opacity,visibility,-webkit-transform;
        transition-property: opacity,visibility,transform;
        transition-property: opacity,visibility,transform,-webkit-transform;
        background-color: #000000f7;
    }
    .header__fader, .header__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        -webkit-transition-duration: .3s;
                transition-duration: .3s;
    }
    .header__fader {
        content: "";
        display: block;
        z-index: 2;
        height: 120px;
        background: -webkit-gradient(linear,left top, left bottom,color-stop(0, #000),color-stop(30%, #000),to(transparent));
        background: linear-gradient(180deg,#000 0,#000 30%,transparent);
        -webkit-transition-property: opacity,visibility;
        transition-property: opacity,visibility;
    }
    .header__menu-links {
        padding: 104px 22px 50px;
    }
    .header__menu-item {
        margin-bottom: 34px;
    }
    /* Contact */
    .header__menu-link.-skin-white.-highlighted {
        background-color: hsla(0,0%,100%,.1);
    }
    .header__menu-link.-skin-white {
        color: #fff!important;
    }

    .header.-active {
        -webkit-transform: none!important;
                transform: none!important;
    }
    .header.-active .header__fader {
        opacity: 1;
        visibility: visible;
    }
    .header.-active .header__overlay {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    .header.-active .header__menu {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    button.header__burger:focus {
        outline: none;
    }
    .header-logo{
        margin-top:5px;
        width:95px;
    }
    /* .upscrolled{
        transition: transform .3s ease-in-out;
        background-color: #fdfdfd;
        border-bottom: 1px solid rgba(0,0,0,.1);
    } */
}



 main{
     display: block;
 }
.section-gradient {
    /* background: linear-gradient(270deg,#0369fb 0,#72da0e 16.67%,#f7c233 33.34%,#ee7e33 50.01%,#e93f33 66.68%,#a552f2 83.35%,#0369fb); */
    background-size: 2000% 400%;
    -webkit-animation: gradient 30s linear infinite;
            animation: gradient 30s linear infinite;
    background-color: #8751f2;
    color: #fff;
}

section {
    display: block;
}

.container {
    position: relative;
    margin: auto;
    max-width: 1268px;
    box-sizing: border-box;
}
.section-gradient__container {
    display: flex;
}

h1, h2, h3 {
    padding: 0;
    margin: 0;
}
h1 {
    display: block;
    font-size: 2em;
    /* margin-block-start: 0.67em;
    margin-block-end: 0.67em; */
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
}

.text>:last-child {
    margin-bottom: 0 !important;
}
.text>:first-child {
    margin-top: 0 !important;
}
.text>p {
    font-weight: 500;
}

p {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

@media only screen and (min-width: 768px){
    .container {
        width: 90%;
    }
    .section-gradient:first-child .section-gradient__container {
        padding-top: 163px;
    }
    .section-gradient__container {
        padding-bottom: 188px;
        justify-content: space-between;
    }
    .section-gradient__text {
        width: 415px;
    }
    .section-gradient__text {
        flex: 0 0 auto;
        padding: 20px 0 0 20px;
    }
    h1 {
        font-size: 60px;
        line-height: 70px;
    }
    h1, h2 {
        font-weight: 600;
    }
    .text.-size-bigger>p {
        font-size: 22px;
        line-height: 35px;
        margin: 35px 0;
    }
    .section-separation{
        padding-top: 87px;
    }
}


@media only screen and (max-width: 767px){
    .section-gradient:first-child .section-gradient__container {
        padding-top: 90px;
    }
    .section-gradient__container {
        flex-direction: column;
        padding-bottom: 92px;
    }
    .section-separation{
        padding-top: 50px;
    }
}
.section__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
}

.headline {
    display: block;
}
.headline__title:after {
    content: "";
    height: 1px;
    background-color: currentColor;
}

.headline__title, .headline__title:after {
    display: inline-block;
    vertical-align: baseline;
}
.headline__title {
    color: currentColor;
    font-weight: 500;
}



@media only screen and (min-width: 768px){
    .headline__title:after {
        width: 31px;
        margin: 0 5px 0 10px;
    }    
    .headline__subtitle, .headline__title {
        font-size: 17px;
        line-height: 24px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }
}


@media only screen and (max-width: 767px){
    .headline__title:after {
        width: 24px;
        margin: 0 2px 0 7px;
    }    
    .headline__subtitle, .headline__title {
        font-size: 16px;
        line-height: 16px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }
}


.service-tile, .service-tile>span {
    display: block;
}

.service-tile {
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
    border-radius: 1px;
    height: 100%;
    box-sizing: border-box;
    color: #000;
    -webkit-transition-property: box-shadow,-webkit-transform;
    transition-property: box-shadow,-webkit-transform;
    transition-property: box-shadow,transform;
    transition-property: box-shadow,transform,-webkit-transform;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
}

.service-tile, .service-tile>span {
    display: block;
}


.service-tile__icon {
    width: 94px;
    height: 94px;
    font-size: 0;
    line-height: 94px;
    margin-bottom: 16px;
    text-align: center;
}
.service-tile__icon img{
    width: 94px;
    height: 94px;
}

.service-tile__title {
    font-weight: 600;
    line-height: 32px;
}
.service-tile__desc {
    position: relative;
    overflow: hidden;
    font-weight: 500;
}

a {
    text-decoration: none;
}
@media only screen and (min-width: 768px){
    .grid.-three .grid__row {
        width: 33.33333%;
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .service-tile {
        padding: 20px 30px 45px;
    }
    .service-tile__title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .service-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}


@media only screen and (max-width: 767px){
    .grid.-three .grid__row {
        width: 100%
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .service-tile {
        padding: 20px 30px 45px;
    }
    .service-tile__title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .service-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}
.grid__row:last-child .solutions-tile:last-of-type{
    border-right: none;
  }


  @media only screen and (min-width: 1024px){

    .fos__links {
      grid-template-columns: repeat(4,1fr);
      grid-gap: 11px 13px;
    }
    .fos__link {
      font-size: 17px;
      line-height: 28px;
      height: 68px;
      padding: 0 9.5% 4px;
  }
  }

  .fos__links {
    display: grid;
}

.fos__link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
  border-radius: 1px;
  font-weight: 600;
  color: #000;
  -webkit-transition-property: box-shadow,-webkit-transform;
  transition-property: box-shadow,-webkit-transform;
  transition-property: box-shadow,transform;
  transition-property: box-shadow,transform,-webkit-transform;
  -webkit-transition-duration: .15s;
          transition-duration: .15s;
}



.small-cart{
  font-size: 17px;
  line-height: 28px;
  height: 68px;
  margin: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
  border-radius: 1px;
  font-weight: 600;
  color: #000;
  -webkit-transition-property: box-shadow,-webkit-transform;
  transition-property: box-shadow,-webkit-transform;
  transition-property: box-shadow,transform;
  transition-property: box-shadow,transform,-webkit-transform;
  -webkit-transition-duration: .15s;
          transition-duration: .15s;
}

.technology-header{
  margin-top: 70px;
    margin-bottom: 70px;
}

.technology-list{

}

.technology-header-title{
  margin:auto;
  text-align:center;
  font-weight: 700;
  font-size: 29px;
}

.technology-header-title h3{
  text-align:center;

}


.solutions-tile, .solutions-tile>span {
    display: block;
}

.solutions-tile {
    background-color: #fff;
    /* box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06); */
    border-radius: 1px;
    border-right: 2px #0000003b dotted;
    height: 100%;
    box-sizing: border-box;
    color: #000;
    -webkit-transition-property: box-shadow,-webkit-transform;
    transition-property: box-shadow,-webkit-transform;
    transition-property: box-shadow,transform;
    transition-property: box-shadow,transform,-webkit-transform;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
}

.solutions-tile, .solutions-tile>span {
    display: block;
}


.solutions-tile__icon {
    font-size: 0;
    line-height: 94px;
    text-align: center;
}
.solutions-tile__icon img{
    /* width: 94px; */
    /* height: 94px; */
    
}

.solutions-tile__title {
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-top:40px;
}
.solutions-tile__desc {
    position: relative;
    overflow: hidden;
    font-weight: 500;
}

a {
    text-decoration: none;
}
@media only screen and (min-width: 768px){
    .grid.-three .grid__row {
        width: 33.33333%;
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .solutions-tile {
        padding: 20px 30px 45px;
    }
    .solutions-tile__title {
        font-size: 22px;
        /* margin-bottom: 10px; */
    }
    .solutions-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}
.contact-container{
    padding-top: 30px;
    width: 80%;
    margin:auto;
}

@media only screen and (min-width: 768px){
    .contact-container {
        padding-bottom: 30px;
        justify-content: space-between;
    }
    .contact__headline {
        text-align: center;
        padding-bottom: 30px;
    }
    .contact-input-container{
        width: 60%;
        margin: auto;
    }
}

@media only screen and (max-width: 767px){
    .contact-container {
        padding-bottom: 20px;
        justify-content: space-between;
    }
    .contact__headline {
        text-align: center;
        padding-bottom: 20px;
    }
    .contact-input-container{
        margin: auto;
    }
}


.contact-input-element{
    position:relative;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    
}

.input-contact{
    display: block;
    width: 100%;
    background: transparent;
    font-size: 16px;
    line-height: 1.2;
    padding: 0 20px 0 23px;
}

.input-contact-text{
    height: 52px;
}

.input-contact-rich-text{
    height:104px;
}
.input-contact-button{
    height:52px;
    font-weight: 600;
}

button {
    outline: none;
    border: none;
}

input {
    outline: none;
    border: none;
}


footer {
    height: 210px;
    width: 100%;
    background-color: #fff;
    font-weight: 500;
}

footer ul {
    padding: 80px 60px 0;
    text-align: center;
    max-width: 1120px;
    margin: 0 auto;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 40px;
            padding-inline-start: 40px;
}

footer ul li:first-child {
    /* color: #999; */
}

footer ul li {
    display: inline-block;
    vertical-align: top;
    width: 24%;
    text-align: left;
    color: #000;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 26px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

footer ul li a {
    text-decoration: none;
}

.footer-newsletter-input{
    border: 2px solid #00000087;
    border-radius: 20px;
    font-size: 16px;
    color: black;
    line-height: 1.2;
    padding: 0 20px 0 23px;
    height: 57px;
    width:100%
}

.button-newsletter{
    border-radius: 20px;
    height: 57px;
    width: 50%;
    margin: 0;
    position: relative;
    top: 100%;
    /* left: 25%; */
    margin-top: 12px;
    font-weight: 600;
}

.newsletter-approval{
    font-size: 13px;
    margin-top:10px;
}

.footer-logo{
    width: 140px;
}
