.grid__row:last-child .solutions-tile:last-of-type{
    border-right: none;
  }


  @media only screen and (min-width: 1024px){

    .fos__links {
      grid-template-columns: repeat(4,1fr);
      grid-gap: 11px 13px;
    }
    .fos__link {
      font-size: 17px;
      line-height: 28px;
      height: 68px;
      padding: 0 9.5% 4px;
  }
  }

  .fos__links {
    display: grid;
}

.fos__link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
  border-radius: 1px;
  font-weight: 600;
  color: #000;
  transition-property: box-shadow,transform;
  transition-duration: .15s;
}



.small-cart{
  font-size: 17px;
  line-height: 28px;
  height: 68px;
  margin: 10px;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
  border-radius: 1px;
  font-weight: 600;
  color: #000;
  transition-property: box-shadow,transform;
  transition-duration: .15s;
}

.technology-header{
  margin-top: 70px;
    margin-bottom: 70px;
}

.technology-list{

}

.technology-header-title{
  margin:auto;
  text-align:center;
  font-weight: 700;
  font-size: 29px;
}

.technology-header-title h3{
  text-align:center;

}
