.header {
    position: fixed;
    /* position: absolute; */
    display: block;

    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    /* transition: background-color .5s ease; */
}
    .header.-upscrolled:not(.-active) {
        transition: transform .3s ease-in-out;
        background-color: #fdfdfd;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }

    .header.-upscrolled:not(.-active) .complex-link, .header.-upscrolled:not(.-active) .header__logo, .header.-upscrolled:not(.-active) .header__menu-link {
        color: #000!important;
    }

    .header.-upscrolled:not(.-active) .header__burger {
        border-color: rgba(0,0,0,.5);
    }
.container {
    position: relative;
    margin: auto;
    max-width: 1268px;
    box-sizing: border-box;
}
.header__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}


.header__menu {
    font-weight: 600;
}

.complex-link {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.complex-link.-skin-white {
    color: #fff;
}

a {
    text-decoration: none;
}

.header__menu-link.-highlighted {
    padding: 5px 14px;
}
.header__menu-link {
    -webkit-text-fill-color: currentcolor;
    transition-property: background-color;
    transition-duration: .3s;
    border: none;
    background: none;
}

.header__overlay {
    position: fixed;
    z-index: 1;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity,visibility;
    transition-duration: .3s;
}
.header__burger {
    display: block;
    position: relative;
    z-index: 3;
    border-style: solid;
    border-width: 1px;
    background: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0;
    line-height: 0;
    color: hsla(0,0%,100%,.5);
    transition: all .3s;
}

@media only screen and (min-width: 768px){
    .container {
        width: 90%;
    }
    .header__container {
        transition: height .3s;
        will-change: height;
        height: 98px;
    }
    .header__menu {
        font-size: 16px;
    }
    .header__menu-item {
        display: inline-block;
        vertical-align: top;
        margin-left: 28px;
    }
    /* Contact */
    .header__menu-link.-skin-white.-highlighted {
        background-color: hsla(0,0%,100%,.1);
    }
    .header__menu-link.-skin-white {
        color: #fff;
    }
    .header__burger {
        display: none;
    }
    .header-logo{
        width:120px;
    }

}

@media only screen and (max-width: 767px){
    .container {
        width: 100%;
        padding: 0 22px;
    }
    .header__container {
        margin-top: 5px;
        height: 57px;
    }
    .header__menu {
        font-size: 17px;
        z-index: 1;
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        transition-property: opacity,visibility,transform;
        background-color: #000000f7;
    }
    .header__fader, .header__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition-duration: .3s;
    }
    .header__fader {
        content: "";
        display: block;
        z-index: 2;
        height: 120px;
        background: linear-gradient(180deg,#000 0,#000 30%,transparent);
        transition-property: opacity,visibility;
    }
    .header__menu-links {
        padding: 104px 22px 50px;
    }
    .header__menu-item {
        margin-bottom: 34px;
    }
    /* Contact */
    .header__menu-link.-skin-white.-highlighted {
        background-color: hsla(0,0%,100%,.1);
    }
    .header__menu-link.-skin-white {
        color: #fff!important;
    }

    .header.-active {
        transform: none!important;
    }
    .header.-active .header__fader {
        opacity: 1;
        visibility: visible;
    }
    .header.-active .header__overlay {
        transform: scale(1);
    }
    .header.-active .header__menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    button.header__burger:focus {
        outline: none;
    }
    .header-logo{
        margin-top:5px;
        width:95px;
    }
    /* .upscrolled{
        transition: transform .3s ease-in-out;
        background-color: #fdfdfd;
        border-bottom: 1px solid rgba(0,0,0,.1);
    } */
}


