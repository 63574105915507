.section__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
}

.headline {
    display: block;
}
.headline__title:after {
    content: "";
    height: 1px;
    background-color: currentColor;
}

.headline__title, .headline__title:after {
    display: inline-block;
    vertical-align: baseline;
}
.headline__title {
    color: currentColor;
    font-weight: 500;
}



@media only screen and (min-width: 768px){
    .headline__title:after {
        width: 31px;
        margin: 0 5px 0 10px;
    }    
    .headline__subtitle, .headline__title {
        font-size: 17px;
        line-height: 24px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }
}


@media only screen and (max-width: 767px){
    .headline__title:after {
        width: 24px;
        margin: 0 2px 0 7px;
    }    
    .headline__subtitle, .headline__title {
        font-size: 16px;
        line-height: 16px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }
}
