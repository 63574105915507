 main{
     display: block;
 }
.section-gradient {
    /* background: linear-gradient(270deg,#0369fb 0,#72da0e 16.67%,#f7c233 33.34%,#ee7e33 50.01%,#e93f33 66.68%,#a552f2 83.35%,#0369fb); */
    background-size: 2000% 400%;
    animation: gradient 30s linear infinite;
    background-color: #8751f2;
    color: #fff;
}

section {
    display: block;
}

.container {
    position: relative;
    margin: auto;
    max-width: 1268px;
    box-sizing: border-box;
}
.section-gradient__container {
    display: -ms-flexbox;
    display: flex;
}

h1, h2, h3 {
    padding: 0;
    margin: 0;
}
h1 {
    display: block;
    font-size: 2em;
    /* margin-block-start: 0.67em;
    margin-block-end: 0.67em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.text>:last-child {
    margin-bottom: 0 !important;
}
.text>:first-child {
    margin-top: 0 !important;
}
.text>p {
    font-weight: 500;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media only screen and (min-width: 768px){
    .container {
        width: 90%;
    }
    .section-gradient:first-child .section-gradient__container {
        padding-top: 163px;
    }
    .section-gradient__container {
        padding-bottom: 188px;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .section-gradient__text {
        width: 415px;
    }
    .section-gradient__text {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: 20px 0 0 20px;
    }
    h1 {
        font-size: 60px;
        line-height: 70px;
    }
    h1, h2 {
        font-weight: 600;
    }
    .text.-size-bigger>p {
        font-size: 22px;
        line-height: 35px;
        margin: 35px 0;
    }
    .section-separation{
        padding-top: 87px;
    }
}


@media only screen and (max-width: 767px){
    .section-gradient:first-child .section-gradient__container {
        padding-top: 90px;
    }
    .section-gradient__container {
        -ms-flex-direction: column;
        flex-direction: column;
        padding-bottom: 92px;
    }
    .section-separation{
        padding-top: 50px;
    }
}