
.service-tile, .service-tile>span {
    display: block;
}

.service-tile {
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.03), 0 3px 15px 0 rgba(0,0,0,.06);
    border-radius: 1px;
    height: 100%;
    box-sizing: border-box;
    color: #000;
    transition-property: box-shadow,transform;
    transition-duration: .15s;
}

.service-tile, .service-tile>span {
    display: block;
}


.service-tile__icon {
    width: 94px;
    height: 94px;
    font-size: 0;
    line-height: 94px;
    margin-bottom: 16px;
    text-align: center;
}
.service-tile__icon img{
    width: 94px;
    height: 94px;
}

.service-tile__title {
    font-weight: 600;
    line-height: 32px;
}
.service-tile__desc {
    position: relative;
    overflow: hidden;
    font-weight: 500;
}

a {
    text-decoration: none;
}
@media only screen and (min-width: 768px){
    .grid.-three .grid__row {
        width: 33.33333%;
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .service-tile {
        padding: 20px 30px 45px;
    }
    .service-tile__title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .service-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}


@media only screen and (max-width: 767px){
    .grid.-three .grid__row {
        width: 100%
    }
    .grid__row {
        box-sizing: border-box;
        padding: 5px;
    }
    .service-tile {
        padding: 20px 30px 45px;
    }
    .service-tile__title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .service-tile__desc {
        font-size: 20px;
        line-height: 31px;
    }
    
}